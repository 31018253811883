<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.cashiers') }}</h5>
                <DataTable
                    :lazy="true"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    :totalRecords="totalPages"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    stateStorage="session"
                    stateKey="dt-state-cashiers"
                >
                    <template #header>
                        <div class="p-grid">
                            <div class="p-input-icon-left p-col-12 p-md-2">
                                <i class="pi pi-search" />
                                <InputText @input="search" v-model="searchTerm" :placeholder="$t('search.byName')" style="width: 100%" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" :showClear="true" v-model="shopsModel" :options="shops" optionLabel="name" :placeholder="$t('search.byShop')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="activeFilter" :options="activeOptions" :placeholder="$t('search.byStatus')" optionLabel="option" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="managerFilter" :options="managerOptions" :placeholder="$t('search.byType')" optionLabel="option" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-2 p-text-right"><Button :label="$t('buttons.add')" class="p-button-raised p-button-success" @click="goto(editPage, { id: 0 })" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="username" :header="$t('system.username')" :sortable="true"></Column>
                    <Column field="firstName" :header="$t('system.firstName')" :sortable="true"></Column>
                    <Column field="lastName" :header="$t('system.lastName')" :sortable="true"></Column>
                    <Column field="bettingOffices" :header="$t('system.shops')" :sortable="true">
                        <template #body="{ data }">
                            <span>{{ data.bettingOffices.length > 0 ? data.bettingOffices.join().substring(0, 60) : $t('system.allShops') }}</span>
                        </template>
                    </Column>

                    <Column field="manager" :header="$t('system.manager')" :style="{ width: '200px' }">
                        <template #body="{ data }">
                            <span v-if="data.isManager" :class="'custom-badge status-' + data.isManager">{{ $t('system.manager') }}</span>
                        </template>
                    </Column>
                    <Column field="allowedTransactions" :header="$t('system.allowedTransactions')">
                        <template #body="{ data }">
                            <span>{{ data.allowedTransactions != null ? data.allowedTransactions.join() : '' }}</span>
                        </template>
                    </Column>

                    <Column field="active" :header="$t('general.status')" :style="{ width: '200px' }">
                        <template #body="{ data }">
                            <span :class="'custom-badge status-' + data.active">{{ data.active ? $t('general.active') : $t('general.inactive') }}</span>
                        </template>
                    </Column>
                    <Column :exportable="false" class="p-text-right" v-show="hasAccess(availableMenus, editPage)">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto(editPage, { id: slotProps.data._id })" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
import { debounce } from 'lodash';
export default {
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            BoApi: new BoApi(),
            searchTerm: '',
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            crtRoute: 'cashiers',
            activeFilter: { id: 'all', option: 'All' },
            filtersInitializationDone: false,
            managerFilter: { id: 'all', option: 'All' },
            defaultManagerId: 'all',
            shops: [],
            shopsModel: { _id: 'all', name: 'All' },
            defaultShopId: 'all',
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
        activeOptions() {
            return [
                {
                    id: 'all',
                    option: this.$t('general.all'),
                },
                {
                    id: 'active',
                    option: this.$t('general.active'),
                },
                {
                    id: 'inactive',
                    option: this.$t('general.inactive'),
                },
            ];
        },
        managerOptions() {
            return [
                {
                    id: 'all',
                    option: this.$t('general.all'),
                },
                {
                    id: 'manager',
                    option: this.$t('system.managerCashier'),
                },
                {
                    id: 'regular',
                    option: this.$t('system.regularCashier'),
                },
            ];
        },
        availableMenus() {
            return this.$store.getters['user/availableMenus'];
        },
    },
    watch: {
        activeFilter() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
        managerFilter() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
        shopsModel() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
    },
    mounted() {
        this.BoApi.getShops('')
            .then((response) => {
                this.shops = response.data;
                this.shops.unshift(this.shopsModel);
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
        if (this.storeFilterData != undefined) {
            this.searchTerm = this.storeFilterData.data.search;
            this.sort = this.storeFilterData.data.sort;
            this.activeFilter = this.activeOptions.find((s) => s.id == this.storeFilterData.data.filter);
            this.shopsModel = this.shops.find((s) => s.id == this.storeFilterData.data.shopId);
            this.managerFilter = this.managerOptions.find((s) => s.id == this.storeFilterData.data.manager);
            this.readItems(this.storeFilterData.data.page);
        } else {
            this.readItems();
        }
    },
    methods: {
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            const params = {
                per_page: this.perPage,
                page: page,
                search: this.searchTerm,
                sort: this.sort,
                filter: this.notNullOrEmptyObject(this.activeFilter) ? this.activeFilter.id : this.defaultActiveFilterId,
                manager: this.notNullOrEmptyObject(this.managerFilter) ? this.managerFilter.id : this.defaultManagerId,
                shopId: this.notNullOrEmptyObject(this.shopsModel) ? this.shopsModel._id : this.defaultShopId,
            };
            this.BoApi.getCashiers(params)
                .then((response) => {
                    this.items = response.data.data;
                    this.totalPages = response.data.total;
                    this.loading = false;
                    this.filtersInitializationDone = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        onPage(event) {
            this.readItems(event.page + 1);
            this.composeAndSaveFiltersToStore();
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
            this.composeAndSaveFiltersToStore();
        },
        composeAndSaveFiltersToStore() {
            let filters = {
                per_page: this.perPage,
                page: this.crtPage,
                search: this.searchTerm,
                sort: this.sort,
                filter: this.notNullOrEmptyObject(this.activeFilter) ? this.activeFilter.id : this.defaultActiveFilterId,
                manager: this.notNullOrEmptyObject(this.managerFilter) ? this.managerFilter.id : this.defaultManagerId,
                shopId: this.notNullOrEmptyObject(this.shopsModel) ? this.shopsModel.id : this.defaultShopId,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        search: debounce(function () {
            this.composeAndSaveFiltersToStore();
            this.readItems();
        }, 400),
    },
};
</script>
